<template>
  <a-row :gutter="24" type="flex">
    <a-col :span="24" class="mb-24">
      <a-card
        :bordered="false"
        class="header-solid h-full"
        :bodyStyle="{ paddingTop: 0 }"
      >
        <template #title>
          <h5 class="font-semibold text-center">New Promotion Add</h5>
        </template>
        <a-form
          id="components-form-demo-normal-login"
          :form="form"
          class="game-upload-form"
          @submit="handleSubmit"
        >
          <a-row :gutter="24" type="flex">
            <a-col :span="24" :md="24" class="mb-24">
              <a-form-item class="mb-10">
                <a-input
                  v-decorator="[
                    'title',
                    {
                      rules: [
                        { required: true, message: 'Please input title!' },
                      ],
                    },
                  ]"
                  placeholder="Title"
                >
                </a-input>
              </a-form-item>

              <a-form-item class="mb-10">
                <a-textarea
                  v-decorator="[
                    'body',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please input  body!',
                        },
                      ],
                    },
                  ]"
                  placeholder="Body"
                >
                </a-textarea>
              </a-form-item>
              <a-form-item>
                <a-select
                  placeholder="Select Event Feature"
                  v-decorator="[
                    'event_id',
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Please select Event Feature !',
                        },
                      ],
                    },
                  ]"
                >
                  <a-select-option v-for="(value, id) in featureList" :key="value.id">
                    {{ value.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item class="mb-10">
                <div
                  style="
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
                    gap: 5px;
                  "
                >
                  <div>
                    <label>Published: </label>
                    <a-switch
                      v-model:checked="isPublished"
                      style="margin-left: 6px"
                    />
                  </div>
                </div>
              </a-form-item>
 
              <a-form-item class="mb-10">
                <label> Image</label>
                <a-input type="file" accept="image/*" @change="imageUploadChange($event)">
                </a-input>
              </a-form-item>
            </a-col>
          </a-row>
          <a-form-item>
            <a-button
              type="primary"
              block
              html-type="submit"
              class="login-form-button"
            >
              <a-spin v-if="confirmLoading" />
              Upload
            </a-button>
          </a-form-item>
   
        </a-form>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import { sendRequest } from "@/http/axios.method";
import event from "ant-design-vue/lib/_util/css-animation/Event";

export default {
  name: "NewGameUpload",
  computed: {
    event() {
      return event;
    },
  },
  data() {
    return {
      config: {
        Authorization: "Bearer " + this.$store.state.authUser.token,
      },
      confirmLoading: false,
      isPublished: false,
      profileImage: "",
      featureList: [],
    };
  },
  beforeCreate() {
    // Creates the form and adds to it component's "form" property.
    this.form = this.$form.createForm(this, { name: "new_game_upload" });
  },
  created() {
    this.getEventList();
  },
  methods: {
    imageUploadChange(event, type) {
      this.profileImage = event.target.files[0];
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.confirmLoading = true;
          let form_data = new FormData();
          let configHeader = {
            "content-type": "multipart/form-data",
            Authorization: "Bearer " + this.$store.state.authUser.token,
          };
          let data = {
            title: values.title,
            body: values.body,
            published_status: this.isPublished === true ? 1 : 0,
            event_id: values.event_id ? values.event_id : '',
          };
          for (let key in data) {
            form_data.append(key, data[key]);
          }

         
          if(this.profileImage){
            form_data.append("image", this.profileImage);
          }

          console.log(form_data);
          sendRequest("post", "admin/promotion-create", form_data, configHeader)
            .then((response) => {
              if (response.success) {
                this.confirmLoading = false;
                this.$router.push("/promotion");
              } else {
                if (response.status_code === 401) {
                  this.$store.dispatch("logout");
                  this.$router.push("/login");
                }
              }
            })
            .catch(() => {
              this.confirmLoading = false;
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    },
    getEventList() {
      sendRequest("get", "admin/getEvents", {}, this.config)
        .then((response) => {
          if (response.success) {
            let data = response.data;
            this.featureList = data.map(function (content, label) {
              return {
                id: content.id,
                name: content.event_name,
              };
            });
          } else {
            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            }
          }
        })
        .catch(() => {})
        .finally(() => {});
    }
  },
};
</script>

<style scoped></style>
